'use client';

import { Trans, useTranslation } from '@whoop/i18n/lang/client';
import { Modal, ModalContent } from '../ModalV2/Modal';
import { Button } from '../ButtonV2/Button';

export interface UpgradeModalProps
  extends React.HTMLAttributes<HTMLDivElement> {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  email: string;
  handleUpgradeClick: () => void;
}

export function UpgradeModal({
  isOpen,
  setIsOpen,
  email,
  handleUpgradeClick,
}: UpgradeModalProps): JSX.Element {
  const { t } = useTranslation('upgradeModal');

  return (
    <Modal onOpenChange={setIsOpen} open={isOpen}>
      <ModalContent className=' md:w-[460px]'>
        <div className='flex w-full flex-col items-center px-10 py-8 text-center'>
          <h1 className='text-4xl font-semibold uppercase'>{t('title')}</h1>
          <p className='py-5'>
            <Trans>{t('description', { email })}</Trans>
          </p>
          <Button onClick={handleUpgradeClick} size='medium' variant='primary'>
            {t('upgradeButton')}
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
}
