'use client';

import { useEffect, useRef, useState } from 'react';
import { useTranslation } from '@whoop/i18n/lang/client';
import { FormProvider, useForm } from 'react-hook-form';
import { FormInputType } from '../../../types';
import type { EmailValidationResponse, UserDetails } from '../../../types';
import { validateEmail } from '../../../utils';
import Input from '../../Input/Input';

interface CreateAccountFormProps {
  onSubmit: (user: UserDetails) => void;
  onValidationReset: () => void;
  isOpen: boolean;
  onStart: () => void;
  userDetails?: UserDetails;
  validationResponse?: EmailValidationResponse;
  formError?: string;
  formSubmitDisabled: (isSubmitDisabled: boolean) => void;
}

const defaultUserDetails = {
  email: '',
  password: '',
};

export function CreateAccountForm({
  onSubmit,
  onValidationReset,
  userDetails,
  validationResponse,
  formError,
  isOpen,
  onStart,
  formSubmitDisabled,
  ...props
}: CreateAccountFormProps): JSX.Element {
  const { is_valid: isEmailValid, suggested_email: suggestedEmail } =
    validationResponse ?? {};
  const methods = useForm();
  const { t } = useTranslation('orderPage');
  const [hasStarted, setHasStarted] = useState(false);
  const [email, _setEmail] = useState<string>(
    userDetails?.email ?? defaultUserDetails.email,
  );
  const [emailError, setEmailError] = useState<string>();
  const [emailSuggestion, setEmailSuggestion] = useState<string>();

  const [password, _setPassword] = useState<string>(
    userDetails?.password ?? defaultUserDetails.password,
  );
  const [passwordError, setPasswordError] = useState<string>();

  const [confirmPassword, _setConfirmPassword] = useState<string>('');
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>();

  const genericEmailError = t('validEmail');

  const emailRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      // @ts-expect-error forceVisible is an experimental property not supported by all browsers
      emailRef.current?.focus({ forceVisible: true });
    }
  }, [isOpen, emailRef]);

  useEffect(() => {
    if (suggestedEmail) {
      setEmailSuggestion(t('suggestedEmail', { suggestedEmail }));
    } else if (isEmailValid !== undefined && !isEmailValid) {
      setEmailError(t('validEmail'));
    }
  }, [isEmailValid, suggestedEmail]);

  useEffect(
    () =>
      formSubmitDisabled(
        !email ||
          !password ||
          !confirmPassword ||
          Boolean(passwordError) ||
          Boolean(confirmPasswordError) ||
          Boolean(emailError) ||
          (isEmailValid !== undefined && !isEmailValid),
      ),
    [
      email,
      password,
      confirmPassword,
      passwordError,
      confirmPasswordError,
      emailError,
      isEmailValid,
    ],
  );

  const setEmail = (text: string): void => {
    if (!hasStarted) {
      setHasStarted(true);
      onStart();
    }
    if (!isEmailValid || !suggestedEmail) {
      onValidationReset();
    }
    setEmailError(undefined);
    setEmailSuggestion(undefined);
    _setEmail(text);
  };

  const validateEmailCheck = (): void => {
    if (!email || email.length === 0) {
      setEmailError(genericEmailError);
      return;
    }
    if (!validateEmail(email)) {
      setEmailError(genericEmailError);
      return;
    }
    setEmailError(undefined);
  };

  const setPassword = (text: string): void => {
    setPasswordError(undefined);
    _setPassword(text);
  };

  const setConfirmPassword = (text: string): void => {
    setConfirmPasswordError(undefined);
    _setConfirmPassword(text);
  };

  const validatePassword = (): void => {
    // password required check
    if (!password || password.length === 0) {
      setPasswordError(t('validPassword'));
      return;
    }
    // password length check
    if (password.length < 6) {
      setPasswordError(t('passwordLengthError'));
      return;
    }
    checkPasswordEquality();
  };

  const checkPasswordEquality = (): boolean => {
    if (password && confirmPassword && confirmPassword !== password) {
      setConfirmPasswordError(t('passwordMatchError'));
      return false;
    }
    setConfirmPasswordError(undefined);
    return true;
  };

  const acceptEmailSuggestion = (): void => {
    setEmail(suggestedEmail ?? '');
    onValidationReset();
  };

  const handleSubmit = (): void => {
    if (checkPasswordEquality()) {
      onSubmit({ email, password });
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        id='create-account-form'
        onSubmit={methods.handleSubmit(handleSubmit)}
        {...props}
        className='w-full'
      >
        <Input
          autoComplete='email'
          className={
            Boolean(emailSuggestion) || Boolean(emailError) ? 'mb-2' : 'mb-6'
          }
          errorMessage={emailSuggestion || emailError}
          errorMessageAction={
            emailSuggestion ? acceptEmailSuggestion : undefined
          }
          hasError={Boolean(emailSuggestion) || Boolean(emailError)}
          id='email'
          label={t('yourEmail')}
          onBlur={validateEmailCheck}
          onChange={setEmail}
          ref={emailRef}
          required
          type={FormInputType.TEXT}
          value={email}
        />
        <Input
          autoComplete='new-password'
          className={passwordError ? 'mb-2' : 'mb-6'}
          errorMessage={passwordError}
          hasError={Boolean(passwordError)}
          id='password'
          label={t('password')}
          onBlur={validatePassword}
          onChange={setPassword}
          required
          type={FormInputType.PASSWORD}
          value={password}
        />
        <Input
          autoComplete='new-password'
          className='mb-6'
          errorMessage={confirmPasswordError}
          hasError={Boolean(confirmPasswordError)}
          id='confirmpassword'
          label={t('confirmPassword')}
          onBlur={checkPasswordEquality}
          onChange={setConfirmPassword}
          required
          type={FormInputType.PASSWORD}
          value={confirmPassword}
        />
        {formError ? (
          <span
            className='text-red-a400'
            data-testid='create-account-form-error'
          >
            {formError}
          </span>
        ) : null}
      </form>
    </FormProvider>
  );
}
