import type { Optional } from './BasicTypes';

export interface ProductNode {
  product_info: ProductInfo;
  children: ProductNode[];
}

export interface PackDetails {
  discount?: Optional<number>;
  pro_discount?: Optional<number>;
  pack_items?: Optional<string[]>;
}

export interface ProductInfo {
  handle: string;
  title: string;
  description?: string;
  message?: Optional<string>;
  colors?: Optional<ProductOption[]>;
  sizes?: Optional<ProductOption[]>;
  items?: ProductItem[];
  inseams?: Optional<ProductOption[]>;
  featured_media?: MediaItem;
  media?: MediaItem[];
  category_label?: string;
  product_pages?: string[];
  product_highlights?: string[];
  sizing_guide?: string;
  storefront?: boolean;
  join_flow?: boolean;
  gen3?: boolean;
  gen4?: boolean;
  mens?: boolean;
  womens?: boolean;
  unisex?: boolean;
  varying_pattern?: boolean;
  active?: Optional<boolean>;
  pack_details?: Optional<PackDetails>;
  sales_channels?: Optional<string[]>;
  discount?: Optional<number>;
  pro_discount?: Optional<number>;
  engraving_gen4_strap_text?: Optional<boolean>;
  engraving_gen4_strap_icon?: Optional<boolean>;
  engraving_gen4_id_text?: Optional<boolean>;
  product_type?: string;
}

export interface FlatProductNode extends ProductInfo {
  children_handles: Optional<string[]>;
  parent_handle: Optional<string>;
  root_handle: Optional<string>;
}

export interface MediaItem {
  id: number;
  type: string;
  url: string;
}

export interface ProductOption {
  label: string;
  handle: string;
  swatch?: string; //this isn't in the backend type but is needed for displaying color swatches
}

export interface ProductItem {
  sku: string;
  title: string;
  color?: Optional<ProductOption>;
  inseam?: Optional<ProductOption>;
  size?: Optional<ProductOption>;
  media?: MediaItem[];
  image_id?: Optional<number>;
  label?: string; // TODO: phase this out
  new?: boolean;
  pro_exclusive?: boolean;
  quantity?: number;
  coming_soon?: boolean;
  on_sale?: boolean;
  employee_discount?: boolean;
  member_only?: boolean;
  gen3?: boolean;
  gen4?: boolean;
  pro_exclude_discount?: boolean;
  free_with_restore?: boolean;
  klaviyo_event?: boolean;
  email_when_oos?: boolean;
  special_sale?: boolean;
  inventory: ProductItemInventory[];
}

export interface ProductItemInventory {
  quantity: number;
  sku: string;
}

export interface Media {
  group_id: number;
  media_id?: number;
  media_key?: string;
  media_type: string;
  url_path: string;
  filename: string;
}

export interface WhoopYourWayBase {
  front_media: Media;
  back_media: Media;
  title: string;
  currency: string;
}
export interface WhoopYourWayOption {
  sku: string;
  color_group: string;
  color_text: string;
  code: string;
  price: number;
  quantity: number;
  swatch: Media;
  front_media: Media;
  back_media: Media;
  // optional, not all components have band types
  band_type_group?: string;
  band_type_text?: string;
}
export interface WhoopYourWayComponent {
  options: WhoopYourWayOption[];
  title: string;
}
export interface WhoopYourWaySelection {
  band: WhoopYourWayOption;
  clasp: WhoopYourWayOption;
  hook: WhoopYourWayOption;
  slider: WhoopYourWayOption;
}
export interface WhoopYourWayConfigurations {
  base: WhoopYourWayBase;
  band: WhoopYourWayComponent;
  clasp: WhoopYourWayComponent;
  hook: WhoopYourWayComponent;
  slider: WhoopYourWayComponent;
}
export type WhoopYourWayComponentName = 'band' | 'clasp' | 'hook' | 'slider';
export const WhoopYourWayComponentNames: WhoopYourWayComponentName[] = [
  'band',
  'clasp',
  'hook',
  'slider',
];
export interface WhoopYourWayBandTypeOption {
  value: string;
  label: string;
  description: string[];
}
