import React from 'react';
import { Price } from '../Price/Price';

export interface SubtotalLine {
  label: string;
  price: string;
  originalPrice?: string;
  isProDiscount?: boolean;
  calculatedAtCheckout?: boolean;
}

export interface SubtotalProps {
  lines: SubtotalLine[];
}

export const Subtotal = ({ lines }: SubtotalProps) => (
  <div className='mx-auto w-full text-sm'>
    {lines.map((line: SubtotalLine) => {
      const { label, calculatedAtCheckout, ...rest } = line;

      return (
        <div aria-label={label} className='flex items-center' key={label}>
          <span className='text-gray-a700 flex-1'>{label}</span>
          {calculatedAtCheckout ? <span>--</span> : <Price {...rest} />}
        </div>
      );
    })}
  </div>
);
