'use client';

import { useEffect, useMemo, useState } from 'react';
import { flattenNodeItems } from '../../../../utils/legacyProductHelpers';
import { ProductMedia } from '../../../ProductMedia/ProductMedia';
import {
  ProductDetailsGrid,
  type ProductDetailsGridProps,
} from '../../../ProductsDetailsGrid/ProductsDetailsGrid';
import type { ProductItem, ProductNode } from '../../../../types/Products';
import type { AnalyticsFunction } from '../../../../types';
import { PackItemSelector } from '../PackItemSelector/PackItemSelector';

export interface PackProductDetailsProps
  extends Omit<ProductDetailsGridProps, 'onChange'> {
  node: ProductNode;
  packNodes: ProductNode[];
  value?: ProductItem[] | undefined;
  onChange?: (items: ProductItem[]) => void;
  onClickFullDetails?: (selection?: ProductItem, node?: ProductNode) => void;
  onAnalyticsEvent?: AnalyticsFunction;
}

export function PackProductDetails({
  node,
  packNodes,
  children,
  title,
  isProDiscount,
  withWhoopProPrice,
  originalPrice,
  price,
  className,
  value,
  onChange,
  onClickFullDetails,
  scrollParentRef,
  onAnalyticsEvent,
  ...props
}: PackProductDetailsProps): JSX.Element {
  const description = node.product_info.description;
  const initProductArray = useMemo(
    () => packNodes.map((node) => flattenNodeItems(node)[0]),
    [],
  );
  const [packItems, setPackItems] = useState<ProductItem[]>(initProductArray);

  // Fires when a new combination of pack items is selected
  // Handles informing the parent component of the new combination + firing an analytics event
  const setSelection = (item: ProductItem, index: number): void => {
    const newArr = [...packItems];
    newArr[index] = item;
    const context: Record<string, string> = {};
    newArr.forEach((product, index) => {
      context[`handle-${index + 1}`] = packNodes[index].product_info.handle;
      context[`sku-${index + 1}`] = product.sku;
      if (product.color) {
        context[`color-${index + 1}`] = product.color.handle;
      }
      if (product.size) {
        context[`size-${index + 1}`] = product.size.handle;
      }
      if (product.inseam) {
        context[`inseam-${index + 1}`] = product.inseam.handle;
      }
    });
    if (packItems[index]?.color?.handle) {
      context.color_old = packItems[index]?.color?.handle ?? '';
    }
    if (packItems[index]?.size?.handle) {
      context.size_old = packItems[index]?.size?.handle ?? '';
    }
    if (packItems[index]?.inseam?.handle) {
      context.inseam_old = packItems[index]?.inseam?.handle ?? '';
    }
    if (item.color) {
      context.color_old = item.color.handle;
    }
    if (item.size) {
      context.size_old = item.size.handle;
    }
    if (item.inseam) {
      context.inseam_old = item.inseam.handle;
    }

    onAnalyticsEvent?.('Pack Details Option Selected', {
      handle: packNodes[index]?.product_info?.handle,
      sku_old: packItems[index]?.sku,
      sku_new: item.sku,
      item_index: index,
      ...context,
    });
    setPackItems(newArr);
    onChange?.(newArr);
  };

  useEffect(() => {
    if (value) {
      setPackItems(value);
    }
  }, [value]);

  return (
    <ProductDetailsGrid
      className={className}
      description={description}
      isFancy={false}
      isProDiscount={isProDiscount}
      media={node.product_info.media?.map((item) => (
        <ProductMedia {...item} key={item.id} />
      ))}
      noImageScroll
      onAnalyticsEvent={onAnalyticsEvent}
      onImageChange={(index) => {
        onAnalyticsEvent?.('Product Details Viewed Image', {
          product_handle: node.product_info.handle,
          image_index: index,
        });
      }}
      originalPrice={originalPrice}
      price={price}
      scrollParentRef={scrollParentRef}
      title={title}
      withWhoopProPrice={withWhoopProPrice}
      {...props}
    >
      <div className='mt-7'>
        {packNodes.map((packItem, index) => (
          <PackItemSelector
            key={index}
            node={packItem}
            onChange={(newValue) => setSelection(newValue, index)}
            onClickFullDetails={onClickFullDetails}
            productIndex={index}
            value={value?.[index]}
          />
        ))}
      </div>
      {children}
    </ProductDetailsGrid>
  );
}
