export const getProductIdFromHandle = async (
  handle: string,
): Promise<string> => {
  const baseUrl = process.env.NEXT_PUBLIC_SHOPIFY_BASE_URL;
  const accessToken = process.env.NEXT_PUBLIC_SHOPIFY_ACCESS_TOKEN || '';

  return fetch(`${baseUrl}/api/2020-07/graphql`, {
    method: 'post',
    headers: {
      'X-Shopify-Storefront-Access-Token': accessToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `query getProductIdFromHandle($handle: String!) {
        productByHandle(handle: $handle) {
          id
        }
      }`,
      variables: {
        handle,
      },
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      return response.data.productByHandle.id.split(
        'gid://shopify/Product/',
      )[1];
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
    });
};
