'use client';

import { useTranslation } from '@whoop/i18n/lang/client';
import clsx from 'clsx';
import { Button } from '../../ButtonV2/Button';
import styles from './newReferAFriend.module.scss';

export interface NewReferAFriendProps
  extends React.HTMLAttributes<HTMLDivElement> {
  onShareClick: () => void;
  imageSrc: string;
  linkCopied?: boolean;
}

export function NewReferAFriend({
  onShareClick,
  className,
  imageSrc,
  linkCopied = false,
  ...props
}: NewReferAFriendProps): JSX.Element {
  const { t } = useTranslation('receiptPage');

  const handleShare = () => {
    onShareClick();
  };

  return (
    <div className={clsx(styles.wrapper, className)} {...props}>
      <img alt={t('rafAltText')} className={styles.image} src={imageSrc} />
      <div className={styles.textContainer}>
        <h3 className={styles.title}>{t('rafTitle')}</h3>
        <p className={styles.subtitle}>{t('rafSubtitle')}</p>
      </div>
      <Button
        className={styles.shareButton}
        onClick={handleShare}
        size='large'
        variant={linkCopied ? 'primary' : 'tertiary'}
      >
        {linkCopied ? t('rafLinkCopied') : t('rafShareButtonText')}
      </Button>
    </div>
  );
}
