/* eslint-disable react/jsx-sort-props */
'use client';

import { useEffect, useRef, useState } from 'react';
import { useTranslation } from '@whoop/i18n/lang/client';
import { getProductIdFromHandle } from '../../utils';
import { useOkendoReviews } from '../ReviewStarRating/reviewUtils';
import styles from './reviewsDetails.module.scss';

interface ReviewsDetailsProps {
  productHandle: string;
}
export function ReviewsDetails({
  productHandle,
}: ReviewsDetailsProps): JSX.Element | null {
  const widgetContainer = useRef(null);
  const [productId, setProductId] = useState<string | undefined>();
  const { t } = useTranslation('accessoriesPage');

  useOkendoReviews(widgetContainer, productId);

  useEffect(() => {
    if (productHandle) {
      getProductIdFromHandle(productHandle).then((id) => {
        setProductId(id);
      });
    }
  }, [productHandle]);

  return productId ? (
    <div className={styles.reviewsDetails}>
      <h1>{t('reviews')}</h1>
      <div
        ref={widgetContainer}
        data-oke-widget
        data-oke-reviews-product-id={`shopify-${productId}`}
      />
    </div>
  ) : null;
}
