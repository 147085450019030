import type { IconProps } from '../../../components/Icon/Icon.util';

export const Edit = ({
  title,
  width = 32,
  height = 32,
  fill = 'none',
  role = 'presentation',
}: IconProps): React.ReactNode => {
  const viewBoxSize = 32;

  return (
    <svg
      fill={fill}
      focusable='false'
      height={height}
      role={role}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
    >
      {title ? <title>{title}</title> : null}

      <path
        clipRule='evenodd'
        d='M27.8119 11.3655C29.4593 9.5078 29.3935 6.66442 27.6145 4.88546C25.8356 3.1065 22.9922 3.04071 21.1345 4.68809C21.0632 4.75135 20.9933 4.81714 20.925 4.88546L6.57954 19.2309C6.33342 19.477 6.12486 19.7557 5.95889 20.0578C5.7973 20.3519 5.67608 20.6682 5.59988 20.9984L4.0135 27.8728C3.92845 28.2413 4.25873 28.5715 4.62724 28.4865L11.5016 26.9001C11.8318 26.8239 12.1481 26.7027 12.4422 26.5411C12.7443 26.3751 13.023 26.1666 13.2691 25.9205L27.6145 11.575C27.6829 11.5067 27.7486 11.4368 27.8119 11.3655ZM26.3597 9.91329C27.2104 8.85961 27.1461 7.31207 26.167 6.33297C25.1879 5.35387 23.6404 5.28964 22.5867 6.14029L26.3597 9.91329ZM21.1284 7.57703L24.923 11.3716L12.5454 23.7492L8.7508 19.9546L21.1284 7.57703ZM7.55055 21.6494L6.56053 25.9395L10.8506 24.9495L7.55055 21.6494Z'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default Edit;
