'use client';

import type { WheelEvent } from 'react';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useTranslation } from '@whoop/i18n/lang/client';
import type {
  AddressField,
  AddressFieldType,
  Country,
  Province,
} from '@whoop/i18n/types/address';
import type { Region } from '@whoop/i18n/types/internationalization';
import {
  validateAddressInput,
  getCountriesForRegion,
  getAddressRequirementsForCountry,
  getAddressFieldErrorKey,
  getProvincesForCountry,
} from '@whoop/i18n/utils/addressHelpers';
import clsx from 'clsx';
import { FormProvider, useForm } from 'react-hook-form';
import type { Address, DropdownOption } from '../../../types';
import { FormInputType } from '../../../types';
import { Button } from '../../ButtonV2/Button';
import Input from '../../Input/Input';
import Select from '../../Select/Select';

export interface ShippingDetailsFormProps {
  region: Region;
  onSubmit: (address: Address) => void;
  address?: Address;
  formError?: string;
  isOpen: boolean;
}

const errorStyles =
  'error dark:text-red-a400 text-red-600 pl-4 flex flex-col text-base';
const gap = 'gap-4';
const inputErrorMargin = 'mb-0';
const inputMargin = 'mb-6';

const defaultAddress = {
  first_name: '',
  last_name: '',
  postal_code: '',
  province: '',
  country: '',
  city: '',
  phone: '',
  line1: '',
  line2: '',
  line3: '',
};

export function ShippingDetailsForm({
  onSubmit,
  address,
  region,
  formError,
  isOpen,
}: ShippingDetailsFormProps): JSX.Element {
  const methods = useForm();
  const { t } = useTranslation('addressForm');
  const [countries, setCountries] = useState<Country[]>([]);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [addressFields, setAddressFields] = useState<AddressField[]>([]);

  const [firstName, _setFirstName] = useState<string>(
    address?.first_name ?? defaultAddress.first_name,
  );
  const [firstNameError, setFirstNameError] = useState<string>('');

  const [lastName, _setLastName] = useState<string>(
    address?.last_name ?? defaultAddress.last_name,
  );
  const [lastNameError, setLastNameError] = useState<string>('');

  const [line1, _setLine1] = useState<string>(
    address?.line1 ?? defaultAddress.line1,
  );
  const [line1Error, setLine1Error] = useState<string>('');

  const [line2, _setLine2] = useState<string>(
    address?.line2 ?? defaultAddress.line2,
  );
  const [line2Error, setLine2Error] = useState<string>('');

  const [country, _setCountry] = useState<string>(
    address?.country ?? defaultAddress.country,
  );

  const [countryError, setCountryError] = useState<string>('');

  const [city, _setCity] = useState<string>(
    address?.city ?? defaultAddress.city,
  );
  const [cityError, setCityError] = useState<string>('');

  const [province, _setProvince] = useState<string>(
    address?.province ?? defaultAddress.province,
  );
  const [provinceError, setProvinceError] = useState<string>('');

  const [postalCode, _setPostalCode] = useState<string>(
    address?.postal_code ?? defaultAddress.postal_code,
  );
  const [postalCodeError, setPostalCodeError] = useState<string>('');

  const [phone, _setPhone] = useState<string>(
    address?.phone ?? defaultAddress.phone,
  );
  const [phoneError, setPhoneError] = useState<string>('');

  const firstNameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      // @ts-expect-error forceVisible is an experimental property not supported by all browsers
      firstNameRef.current?.focus({ forceVisible: true });
    }
  }, [isOpen]);

  useEffect(() => {
    const _countries = getCountriesForRegion(region);
    setCountries(_countries);

    const defaultCountry = _countries[0].alpha2;
    // Only default select a country if there is only one option, or if in the UK region
    // For other regions with multiple countries, don't automatically select a country, but use the first country in the list to initialize the form's fields
    if (_countries.length === 1 || region === 'UK') {
      _setCountry(defaultCountry);
    } else {
      const addressRequirements =
        getAddressRequirementsForCountry(defaultCountry);
      setAddressFields(addressRequirements);
    }
  }, [region]);

  useEffect(() => {
    if (country) {
      const _provinces = getProvincesForCountry(country);
      setProvinces(_provinces);

      if (_provinces.length === 1) {
        _setProvince(_provinces[0].code);
      }

      const addressRequirements = getAddressRequirementsForCountry(country);
      setAddressFields(addressRequirements);
    }
  }, [country]);

  const findField = (
    addressRequirements: AddressField[],
    type: AddressFieldType,
  ): AddressField | undefined => {
    return addressRequirements.find((field) => field.type === type);
  };

  const {
    firstNameField,
    lastNameField,
    line1Field,
    line2Field,
    countryField,
    cityField,
    provinceField,
    postalCodeField,
    phoneField,
  } = useMemo(() => {
    const firstNameField = findField(addressFields, 'first_name');
    const lastNameField = findField(addressFields, 'last_name');
    const line1Field = findField(addressFields, 'line1');
    const line2Field = findField(addressFields, 'line2');
    const countryField = findField(addressFields, 'country');
    const cityField = findField(addressFields, 'city');
    const provinceField = findField(addressFields, 'province');
    const postalCodeField = findField(addressFields, 'postal_code');
    const phoneField = findField(addressFields, 'phone');

    return {
      firstNameField,
      lastNameField,
      line1Field,
      line2Field,
      countryField,
      cityField,
      provinceField,
      postalCodeField,
      phoneField,
    };
  }, [addressFields]);

  const handleProvinceSelection = (code: string): void => {
    setProvince(code);
  };

  const handleCountrySelection = (alpha2: string): void => {
    setCountry(alpha2);
  };

  const hasFieldLevelError =
    Boolean(firstNameError) ||
    Boolean(lastNameError) ||
    Boolean(line1Error) ||
    Boolean(line2Error) ||
    Boolean(cityError) ||
    Boolean(provinceError) ||
    Boolean(postalCodeError) ||
    Boolean(phoneError);

  const isSubmitDisabled =
    !firstName ||
    !lastName ||
    !line1 ||
    !country ||
    !city ||
    (postalCodeField?.required && !postalCode) ||
    !phone ||
    hasFieldLevelError;

  const getFieldLabel = (field?: AddressField): string => {
    if (!field?.label) return '';
    return t(field.label);
  };

  const validateField = (
    value: string,
    field: AddressField | undefined,
    errorSetter: React.Dispatch<React.SetStateAction<string>>,
  ): void => {
    if (!field) return;
    const { isValid, errorType } = validateAddressInput(field, value);
    if (isValid) {
      errorSetter('');
    } else if (errorType) {
      const errorKey = getAddressFieldErrorKey(field, errorType);
      const errorMessage = t(errorKey, {
        field: getFieldLabel(field),
        example: field.example ?? '',
        maxLength: field.maxLength ?? '',
        // This prevents any characters passed in through the "field" or "example" values from being escaped
        // For example prevents "State/Province/Region" from becoming "State&#x2F;Province&#x2F;Region"
        interpolation: { escapeValue: false },
      });
      errorSetter(errorMessage);
    }
  };

  const getDisplayCountries = (countries: Country[]): DropdownOption[] => {
    return countries.map((country) => {
      return { label: country.name, value: country.alpha2 };
    });
  };

  const getDisplayProvinces = (provinces: Province[]): DropdownOption[] => {
    return provinces.map((province) => {
      return { label: province.name, value: province.code };
    });
  };

  const setFirstName = (text: string): void => {
    setFirstNameError('');
    _setFirstName(text);
  };

  const setLastName = (text: string): void => {
    setLastNameError('');
    _setLastName(text);
  };

  const setLine1 = (text: string): void => {
    setLine1Error('');
    _setLine1(text);
  };

  const setLine2 = (text: string): void => {
    setLine2Error('');
    _setLine2(text);
  };

  const setCountry = (text: string): void => {
    setCountryError('');
    _setCountry(text);
  };

  const setCity = (text: string): void => {
    setCityError('');
    _setCity(text);
  };

  const setProvince = (text: string): void => {
    setProvinceError('');
    _setProvince(text);
  };

  const setPostalCode = (text: string): void => {
    setPostalCodeError('');
    _setPostalCode(text);
  };

  const setPhone = (text: string): void => {
    setPhoneError('');
    _setPhone(text);
  };

  const handleSubmit = (): void => {
    if (provinceField?.required && province.length <= 0) {
      validateField(province, provinceField, setProvinceError);
    } else if (countryField?.required && country.length <= 0) {
      validateField(country, countryField, setCountryError);
    } else {
      const address = {
        first_name: firstName,
        last_name: lastName,
        postal_code: postalCode,
        province,
        country,
        city,
        phone,
        line1,
        line2,
      };
      onSubmit(address);
    }
  };

  const preventScrollOnNumInput = (event: WheelEvent<HTMLDivElement>): void => {
    // blur instead of scrolling
    (event.target as HTMLElement).blur();
    // set focus again immediately to allow user to scroll on the page itself
    setTimeout(() => {
      (event.target as HTMLElement).focus();
    }, 0);
  };

  const showProvinceDropdown = useMemo(() => {
    return provinces.length > 0;
  }, [provinces]);

  const row1Errors = [firstNameError, lastNameError];
  const showRow1Errors = row1Errors.some((error) => Boolean(error));

  return (
    <FormProvider {...methods}>
      <form
        className='grid grid-cols-1'
        id='shipping-address-form'
        onSubmit={methods.handleSubmit(handleSubmit)}
      >
        <div className={showRow1Errors ? inputErrorMargin : inputMargin}>
          <div className={clsx('grid grid-cols-2', gap)}>
            <Input
              autoComplete={firstNameField?.autoCompleteField}
              hasError={Boolean(firstNameError)}
              id='first-name'
              label={getFieldLabel(firstNameField)}
              name={firstNameField?.type}
              onBlur={() =>
                validateField(firstName, firstNameField, setFirstNameError)
              }
              onChange={setFirstName}
              ref={firstNameRef}
              required={firstNameField?.required}
              type={FormInputType.TEXT}
              value={firstName}
            />
            <Input
              autoComplete={lastNameField?.autoCompleteField}
              hasError={Boolean(lastNameError)}
              id='last-name'
              label={getFieldLabel(lastNameField)}
              name={lastNameField?.type}
              onBlur={() =>
                validateField(lastName, lastNameField, setLastNameError)
              }
              onChange={setLastName}
              required={lastNameField?.required}
              type={FormInputType.TEXT}
              value={lastName}
            />
          </div>
          {showRow1Errors ? (
            <div className={errorStyles}>
              {row1Errors.map((error) => {
                return error && <span key={error}> {error} </span>;
              })}
            </div>
          ) : null}
        </div>
        <Input
          autoComplete={line1Field?.autoCompleteField}
          className={line1Error ? inputErrorMargin : inputMargin}
          errorMessage={line1Error}
          hasError={Boolean(line1Error)}
          id='line-1'
          label={getFieldLabel(line1Field)}
          name={line1Field?.type}
          onBlur={() => validateField(line1, line1Field, setLine1Error)}
          onChange={setLine1}
          required={line1Field?.required}
          type={FormInputType.TEXT}
          value={line1}
        />
        <Input
          autoComplete={line2Field?.autoCompleteField}
          className={line2Error ? inputErrorMargin : inputMargin}
          errorMessage={line2Error}
          hasError={Boolean(line2Error)}
          id='line-2'
          label={getFieldLabel(line2Field)}
          name={line2Field?.type}
          onBlur={() => validateField(line2, line2Field, setLine2Error)}
          onChange={setLine2}
          required={line2Field?.required}
          type={FormInputType.TEXT}
          value={line2}
        />
        <Select
          autoComplete={countryField?.autoCompleteField}
          className={countryError ? inputErrorMargin : inputMargin}
          errorMessage={countryError}
          id='country'
          label={getFieldLabel(countryField)}
          onChange={(event) => handleCountrySelection(event.target.value)}
          options={getDisplayCountries(countries)}
          value={country}
        />
        <div className='grid'>
          <Input
            autoComplete={cityField?.autoCompleteField}
            className={cityError ? inputErrorMargin : inputMargin}
            errorMessage={cityError}
            hasError={Boolean(cityError)}
            id='city'
            label={getFieldLabel(cityField)}
            name={cityField?.type}
            onBlur={() => validateField(city, cityField, setCityError)}
            onChange={setCity}
            required={cityField?.required}
            type={FormInputType.TEXT}
            value={city}
          />
          {provinceField ? (
            <>
              {showProvinceDropdown ? (
                <Select
                  autoComplete={provinceField.autoCompleteField}
                  className={provinceError ? inputErrorMargin : inputMargin}
                  data-testid='province-dropdown'
                  errorMessage={provinceError}
                  id='province'
                  label={getFieldLabel(provinceField)}
                  onChange={(event) =>
                    handleProvinceSelection(event.target.value)
                  }
                  options={getDisplayProvinces(provinces)}
                  value={province}
                />
              ) : (
                <Input
                  autoComplete={provinceField.autoCompleteField}
                  className={provinceError ? inputErrorMargin : inputMargin}
                  data-testid='province-input'
                  errorMessage={provinceError}
                  hasError={Boolean(provinceError)}
                  id='province'
                  label={getFieldLabel(provinceField)}
                  name={provinceField.type}
                  onBlur={() =>
                    validateField(province, provinceField, setProvinceError)
                  }
                  onChange={setProvince}
                  required={provinceField.required}
                  type={FormInputType.TEXT}
                  value={province}
                />
              )}
            </>
          ) : null}
          {postalCodeField ? (
            <Input
              autoComplete={postalCodeField.autoCompleteField}
              className={postalCodeError ? inputErrorMargin : inputMargin}
              data-testid='postal-code-input'
              errorMessage={postalCodeError}
              hasError={Boolean(postalCodeError)}
              id='postal-code'
              label={getFieldLabel(postalCodeField)}
              name={postalCodeField.type}
              onBlur={() =>
                validateField(postalCode, postalCodeField, setPostalCodeError)
              }
              onChange={setPostalCode}
              onWheel={preventScrollOnNumInput}
              required={postalCodeField.required}
              type={FormInputType.TEXT}
              value={postalCode}
            />
          ) : null}
        </div>
        <Input
          className={phoneError ? inputErrorMargin : inputMargin}
          errorMessage={phoneError}
          hasError={Boolean(phoneError)}
          id='phone'
          label={getFieldLabel(phoneField)}
          name={phoneField?.type}
          onBlur={() => validateField(phone, phoneField, setPhoneError)}
          onChange={setPhone}
          required={phoneField?.required}
          type={FormInputType.TEL}
          value={phone}
        />
        {!hasFieldLevelError && formError ? (
          <span
            className={errorStyles}
            data-testid='shipping-details-form-error'
          >
            {formError}
          </span>
        ) : null}
        <Button
          disabled={isSubmitDisabled}
          form='shipping-address-form'
          size='medium'
          type='submit'
          variant='primary'
        >
          {t('next')}
        </Button>
      </form>
    </FormProvider>
  );
}
