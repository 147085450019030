import { useEffect } from 'react';
import { useTranslation } from '@whoop/i18n/lang/client';
import {
  getMediaFromSelection,
  useFlattenedProductOptions,
} from '../../../../utils/legacyProductHelpers';
import type { ProductItem, ProductNode } from '../../../../types/Products';
import { SwatchOptionSelect } from '../../../SwatchOptionSelect/SwatchOptionSelect';
import type { BadgeColor } from '../../../Badge';
import Badge from '../../../Badge';

export interface PackItemSelectorProps {
  node: ProductNode;
  value?: ProductItem;
  onChange?: (item: ProductItem) => void;
  productIndex: number;
  onClickFullDetails?: (selection?: ProductItem, node?: ProductNode) => void;
}

export function PackItemSelector({
  node,
  value,
  onChange,
  productIndex,
  onClickFullDetails,
}: PackItemSelectorProps): JSX.Element {
  const { t } = useTranslation('productDetails');

  const [selection, sizeSelectProps, inseamSelectProps, colorSelectProps] =
    useFlattenedProductOptions(node, value);
  const hasVariants = sizeSelectProps || inseamSelectProps || colorSelectProps;

  useEffect(() => {
    onChange?.(selection);
  }, [selection]);

  const selectionImages = getMediaFromSelection(selection, node);
  let imageSrc: string | null = null;
  if (selectionImages && selectionImages.length > 0) {
    imageSrc = selectionImages[0].url;
  }

  const badgeIsNeeded =
    selection.new === true ||
    selection.pro_exclusive === true ||
    selection.quantity === 0;

  const renderBadge = (): JSX.Element | undefined => {
    let color: BadgeColor = 'black';
    let label: string;
    if (selection.coming_soon) {
      label = t('comingSoon');
    } else if (selection.quantity === 0) {
      color = 'gray';
      label = t('outOfStock');
    } else if (selection.pro_exclusive) {
      color = 'pro';
      label = t('whoopProExclusive');
    } else if (selection.on_sale) {
      label = t('onSale');
    } else if (selection.new) {
      label = t('new');
    } else {
      return;
    }
    return <Badge color={color} label={label} />;
  };

  return (
    <div className='mb-7'>
      <h3 className='mb-3 text-2xl font-semibold'>
        {`${productIndex + 1}. ${node.product_info.title}`}
      </h3>
      <div className='my-7 table-row w-full table-fixed'>
        <div className='table-cell w-24'>
          {imageSrc ? (
            <img
              alt={node.product_info.title}
              className='max-w-24 m-auto block max-h-24'
              src={imageSrc}
            />
          ) : null}
        </div>
        <div className='max-w-72 table-cell w-72 pl-6 align-top text-base first:m-0'>
          <div className='m-0'>
            {colorSelectProps ? (
              <div>
                <SwatchOptionSelect
                  name={`${productIndex}-color`}
                  showAllOptions
                  size='small'
                  {...colorSelectProps}
                />
              </div>
            ) : null}
            <h3 className='text-xs'>
              {hasVariants ? (
                <span className='inline-block whitespace-nowrap py-3 pl-1 font-semibold'>
                  {selection.title}
                </span>
              ) : null}
              {badgeIsNeeded ? renderBadge() : null}
            </h3>
            <div>
              {inseamSelectProps ? (
                <div>
                  <SwatchOptionSelect
                    name={`${productIndex}-inseam`}
                    showAllOptions
                    size='small'
                    {...inseamSelectProps}
                  />
                </div>
              ) : null}
              {inseamSelectProps && sizeSelectProps ? (
                <div className='my-1' />
              ) : null}
              {sizeSelectProps ? (
                <div>
                  <SwatchOptionSelect
                    name={`${productIndex}-size`}
                    showAllOptions
                    size='small'
                    {...sizeSelectProps}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className='mt-3 pl-1'>
            {onClickFullDetails ? (
              <button
                aria-label={node.product_info.title}
                className='no-color'
                onClick={() => onClickFullDetails(selection, node)}
                type='button'
              >
                <div className='cursor-pointer font-semibold text-black underline'>
                  {t('seeFullProductDetails')}
                </div>
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
