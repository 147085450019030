'use client';

import { useMemo, useState } from 'react';
import { useTranslation } from '@whoop/i18n/lang/client';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '../../ButtonV2/Button';
import { FormInputType } from '../../../types';
import { validateEmail } from '../../../utils';
import Input from '../../Input/Input';

interface GiftPurchaserFormProps {
  onSubmit: (email: string) => void;
  email?: string;
  formError?: string;
  onStart: () => void;
}

export function GiftPurchaserForm({
  onSubmit,
  email,
  formError,
  onStart,
}: GiftPurchaserFormProps): JSX.Element {
  const methods = useForm();
  const { t } = useTranslation('orderPage');
  const [gifterEmail, setGifterEmail] = useState<string>(email ?? '');
  const [emailError, setEmailError] = useState<string>();
  const [hasStarted, setHasStarted] = useState(false);

  const genericEmailError = t('validEmail');

  const isSubmitDisabled = useMemo(
    () => !gifterEmail || Boolean(emailError),
    [gifterEmail, emailError],
  );

  const setEmail = (email: string): void => {
    if (!hasStarted) {
      setHasStarted(true);
      onStart();
    }
    setEmailError(undefined);
    setGifterEmail(email);
  };

  const validateEmailCheck = (): void => {
    if (!gifterEmail || gifterEmail.length === 0) {
      setEmailError(genericEmailError);
      return;
    }
    if (!validateEmail(gifterEmail)) {
      setEmailError(genericEmailError);
      return;
    }
    setEmailError(undefined);
  };

  const handleSubmit = (): void => {
    // If the user hits the enter key, this function gets called without the validate email check happening
    // Adding validation here to remedy that
    if (validateEmail(gifterEmail)) {
      onSubmit(gifterEmail);
    } else {
      validateEmailCheck();
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        className='w-full'
        id='gift-purchaser-form'
        onSubmit={methods.handleSubmit(handleSubmit)}
      >
        <Input
          autoComplete='email'
          className={emailError ? 'mb-2' : 'mb-6'}
          errorMessage={emailError}
          hasError={Boolean(emailError)}
          id='email'
          label={t('yourEmail')}
          name='email'
          onBlur={validateEmailCheck}
          onChange={setEmail}
          required
          type={FormInputType.TEXT}
          value={gifterEmail}
        />
        {formError ? (
          <span className='error dark:text-red-a400 pl-4 text-red-600'>
            {formError}
          </span>
        ) : null}
        <Button
          disabled={isSubmitDisabled}
          form='gift-purchaser-form'
          size='medium'
          type='submit'
          variant='primary'
        >
          {t('next')}
        </Button>
      </form>
    </FormProvider>
  );
}
