'use client';
import { useTranslation } from '@whoop/i18n/lang/client';
import { useState } from 'react';
import { shareReferralMessage } from '../../utils/RAFHelpers';
import type { PlacedOrder } from '../../types';
import styles from './referAFriend.module.scss';
import { NewReferAFriend } from './NewReferAFriend/NewReferAFriend';

interface IReferAFriendProps {
  placedOrderMessage: string | undefined;
  trackEvent: () => void;
  imageSrc: string;
}

export function ReferAFriend({
  placedOrderMessage,
  trackEvent,
  imageSrc,
}: IReferAFriendProps): JSX.Element | null {
  const { t } = useTranslation('receiptPage');

  const [linkCopied, setLinkCopied] = useState(false);

  if (!placedOrderMessage) return null;

  const onShareClick = async (): Promise<void> => {
    const _linkCopied = await shareReferralMessage({
      referralMessage: placedOrderMessage,
      title: t('whoopReferral'),
    });
    setLinkCopied(_linkCopied);
    trackEvent();
  };

  return (
    <div className={styles.rafSection}>
      <NewReferAFriend
        imageSrc={imageSrc}
        linkCopied={linkCopied}
        onShareClick={onShareClick}
      />
    </div>
  );
}
