import React from 'react';
import clsx from 'clsx';
import type { DropdownOption } from '../../types';
import CaretDown from '../../icons/icons/Navigation/CaretDown';

export interface SelectProps {
  id: string;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options: DropdownOption[];
  errorMessage?: string;
  name?: string;
  onBlur?: (HTMLSelectElement) => void;
  disabled?: boolean;
  autoComplete?: string;
  className?: string;
  value?: string;
  useV2Style?: boolean;
}

/**
 * @deprecated Use SelectV2/Select instead.
 */
const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      id,
      onChange,
      label,
      options,
      name,
      errorMessage,
      onBlur,
      disabled,
      autoComplete,
      className,
      value = '',
      useV2Style,
    },
    ref,
  ) => {
    const optionSelected = Boolean(value.length);
    return (
      <>
        <div className='relative'>
          <select
            aria-label={label}
            autoComplete={autoComplete}
            className={clsx(
              useV2Style
                ? 'border-input ring-offset-background placeholder:text-muted-foreground focus:ring-ring flex h-14 w-full appearance-none items-center justify-between whitespace-nowrap rounded-md border bg-transparent px-[14px] pt-4 text-base focus:outline-none focus:ring-2 disabled:cursor-not-allowed disabled:opacity-50 aria-[invalid=true]:border-red-500 [&>span]:line-clamp-1'
                : 'disabled:bg-gray-10 peer h-16 w-full cursor-pointer appearance-none rounded-md border-2 border-gray-300 pl-4 pr-8 pt-4 text-black focus:outline-none focus:ring-2 disabled:cursor-auto',
              !useV2Style && errorMessage
                ? 'dark:border-red-a400 border-red-500'
                : '',
              className,
            )}
            disabled={disabled}
            id={id}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={label}
            ref={ref}
            value={value}
          >
            <option disabled key='empty' selected value='' />
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          <div
            className={clsx(
              'pointer-events-none absolute bottom-0 right-2 top-0 m-auto flex items-center justify-center',
              useV2Style ? 'mr-2' : 'mb-6',
              errorMessage ? 'mb-0' : '',
            )}
          >
            <CaretDown
              fill='black'
              height={useV2Style ? 12 : 20}
              title='toggle open'
              width={useV2Style ? 12 : 20}
            />
          </div>

          <label
            className={clsx(
              'pointer-events-none absolute left-2 top-2 z-10 block px-2 transition-all duration-300',
              useV2Style
                ? optionSelected
                  ? 'text-gray-60 top-[6px] text-[10px]'
                  : 'text-gray-60 top-[18px] px-2 text-[14px]'
                : optionSelected
                ? 'top-2 ml-0.5 text-sm'
                : 'top-5 ml-0.5 text-base',
            )}
            htmlFor={id}
          >
            {label}
          </label>
        </div>
        {errorMessage ? (
          <span className='error pl-4 text-red-600'>{errorMessage}</span>
        ) : null}
      </>
    );
  },
);

Select.displayName = 'Select';
export default Select;
