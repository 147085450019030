'use client';

import React, { useState } from 'react';
import clsx from 'clsx';
import type { CheckoutStepStatus } from '../../../types/CheckoutForms';
import Edit from '../../../icons/icons/Actions/Edit';
import Check from '../../../icons/icons/Actions/Check';
import styles from './checkoutSection.module.scss';

export interface CheckoutSectionProps
  extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  status: CheckoutStepStatus;
  onEdit?: () => void;
}

export function CheckoutSection({
  title,
  status,
  onEdit,
  className,
  children,
}: CheckoutSectionProps): JSX.Element {
  const [hovering, setHovering] = useState(false);
  const { isCompleted, isLoading, isOpen } = status;
  const isDisabled = !isCompleted || isLoading;
  const showIcon = isCompleted && !isOpen;

  const getIconClasses = (): string => {
    return clsx(styles.status, showIcon ? styles.showIcon : '');
  };

  const getIcon = (): JSX.Element | null => {
    if (!showIcon) return null;
    const size = 18;
    return hovering ? (
      <Edit
        fill='white'
        height={size}
        title='Edit checkout section'
        width={size}
      />
    ) : (
      <Check
        fill='white'
        height={size}
        title='Completed checkout section'
        width={size}
      />
    );
  };

  return (
    <div className={clsx(className, styles.checkoutSection)}>
      <div className={styles.container}>
        <button
          className={clsx(
            styles.toggle,
            'whoop-typography',
            isDisabled ? '' : styles.clickable,
            className,
          )}
          disabled={isDisabled}
          onClick={onEdit}
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          type='button'
        >
          <div className={styles.titleContainer}>
            <p className={clsx(styles.title)}>{title}</p>
            <div className={getIconClasses()}>{getIcon()}</div>
          </div>
        </button>
        <div className={clsx(styles.collapsible, isOpen ? styles.open : '')}>
          {children}
        </div>
      </div>
    </div>
  );
}
