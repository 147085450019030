'use client';

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from '@whoop/i18n/lang/client';
import type { SiteFormatValues } from '@whoop/i18n/utils/priceHelpers';
import { formatPriceWithSiteValues } from '@whoop/i18n/utils/priceHelpers';
import clsx from 'clsx';
import {
  buildWywCompositeSku,
  getImagesFromSelection,
  urlFromMedia,
} from '../../../utils';
import {
  WhoopYourWayComponentNames,
  type WhoopYourWayBandTypeOption,
  type WhoopYourWayComponentName,
  type WhoopYourWayConfigurations,
  type WhoopYourWayOption,
  type WhoopYourWaySelection,
} from '../../../types/Products';
import type { AnalyticsFunction } from '../../../types';
import { ProductDetailsGrid } from '../../ProductsDetailsGrid/ProductsDetailsGrid';
import type { AccordionSection } from '../../Accordion/Accordion';
import LayeredImage from '../../LayeredImage/LayeredImage';
import { SwatchOptionSelect } from '../../SwatchOptionSelect/SwatchOptionSelect';
import { PreloadedImages } from '../../PreloadedImages/PreloadedImages';
import CaretUp from '../../../icons/icons/Navigation/CaretUp';
import CaretDown from '../../../icons/icons/Navigation/CaretDown';
import type { SubtotalLine } from '../../Subtotal/Subtotal';
import { Subtotal } from '../../Subtotal/Subtotal';
import RadioButton from '../../RadioButton/RadioButton';

const selectedOptionStyles =
  'text-base leading-5 tracking-[0.5px] text-left ml-2 font-whoop';
const h3Styles = 'text-2xl py-6 font-whoopSemibold leading-[18px]';

interface WhoopYourWayProps {
  configurations: WhoopYourWayConfigurations;
  customizationFee?: number;
  discountPercent?: number;
  showAsFree?: boolean;
  value?: WhoopYourWaySelection;
  onChange?: (selection: WhoopYourWaySelection) => void;
  previewClassName?: string;
  infoClassName?: string;
  onAnalyticsEvent?: AnalyticsFunction;
  accordions?: AccordionSection[];
  noImageScroll?: boolean;
  subTitle?: string;
  description?: string;
  imageMessage?: string;
  footer?: React.ReactNode;
  reviewStars?: React.ReactNode;
  children?: React.ReactNode;
  siteValues: SiteFormatValues;
}

const discountPriceByPercent = (price: number, discount: number): number => {
  return price - price * discount;
};

const pickRandomOption = (
  options: WhoopYourWayOption[],
): WhoopYourWayOption => {
  return options[Math.floor(Math.random() * options.length)];
};

const pickRandomBandColorByType = (
  configurations: WhoopYourWayConfigurations,
  bandType: string,
) => {
  //iterate through array and and create array with the selected band type
  const bandOptions = configurations.band.options.filter(
    (band) => band.band_type_group === bandType,
  );
  return pickRandomOption(bandOptions);
};

const randomSelection = (
  configurations: WhoopYourWayConfigurations,
): WhoopYourWaySelection => ({
  band: pickRandomOption(
    configurations.band.options.filter((option) => option.quantity > 0),
  ),
  clasp: pickRandomOption(
    configurations.clasp.options.filter((option) => option.quantity > 0),
  ),
  hook: pickRandomOption(
    configurations.hook.options.filter((option) => option.quantity > 0),
  ),
  slider: pickRandomOption(
    configurations.slider.options.filter((option) => option.quantity > 0),
  ),
});

const getAllImages = (configurations: WhoopYourWayConfigurations): string[] => {
  const images: string[] = [];
  WhoopYourWayComponentNames.forEach((componentName) => {
    configurations[componentName].options.forEach((option) => {
      const { front_media: frontMedia, back_media: backMedia } = option;
      images.push(urlFromMedia(frontMedia));

      // hooks do not have back images
      if (componentName !== 'hook') {
        images.push(urlFromMedia(backMedia));
      }
    });
  });
  return images;
};

export function WhoopYourWay({
  configurations,
  customizationFee = 0,
  discountPercent,
  showAsFree,
  value,
  onChange,
  onAnalyticsEvent,
  previewClassName,
  infoClassName,
  accordions,
  noImageScroll,
  subTitle,
  children,
  description,
  imageMessage,
  footer,
  reviewStars,
  siteValues,
}: WhoopYourWayProps): JSX.Element | null {
  const { t } = useTranslation('whoopYourWay');
  const bandTypeDescriptions: Record<string, string[]> = {
    superknit: [1, 2, 3].map((idx) => t(`superknit.line${idx}`)),
    proknit: [1, 2, 3].map((idx) => t(`proknit.line${idx}`)),
  };

  const bandTypeOptions: WhoopYourWayBandTypeOption[] = useMemo(() => {
    const uniqueBandTypes = configurations.band.options.reduce(
      (groups: Record<string, WhoopYourWayBandTypeOption>, option) => {
        const { band_type_group: bandTypeGroup, band_type_text: bandTypeText } =
          option;
        // this will be undefined if it has not yet been set in the loop
        if (bandTypeGroup && !groups[bandTypeGroup]) {
          groups[bandTypeGroup] = {
            value: bandTypeGroup,
            label: bandTypeText ?? '',
            description: bandTypeText
              ? bandTypeDescriptions[bandTypeGroup]
              : [],
          };
        }
        return groups;
      },
      {},
    );
    return Object.values(uniqueBandTypes);
  }, [configurations]);

  const allImages = useMemo(() => {
    return getAllImages(configurations);
  }, [configurations]);

  const selectedBandType = bandTypeOptions.find(
    ({ value: optionValue }) => optionValue === value?.band.band_type_group,
  );
  const [bandType, setBandType] = useState<
    WhoopYourWayBandTypeOption | undefined
  >(selectedBandType);
  const [showPriceBreakdown, setShowPriceBreakdown] = useState<boolean>(false);

  const changeSelection = (selection: WhoopYourWaySelection): void => {
    onChange && onChange(selection);
    // set the band selection based on the currently selected color
    const selectedBandType = bandTypeOptions.find(
      ({ value }) => value === selection.band.band_type_group,
    );
    if (selectedBandType) {
      setBandType(selectedBandType);
    }
  };

  useEffect(() => {
    changeSelection(randomSelection(configurations));
  }, [configurations]);

  if (!value) return null;

  const getFullOption = (
    optionType: WhoopYourWayComponentName,
    sku: string,
  ): WhoopYourWayOption | undefined =>
    configurations[optionType].options.find((option) => option.sku === sku);
  const updateSelection = (
    componentName: WhoopYourWayComponentName,
    sku: string,
  ): void => {
    const prevSelection = value[componentName];
    const newSelection = { ...value };
    const newComponent = getFullOption(componentName, sku);
    if (newComponent) {
      newSelection[componentName] = newComponent;
      changeSelection(newSelection as WhoopYourWaySelection);
    }
    onAnalyticsEvent?.('WYW Selected Component', {
      component_name: componentName,
      component_sku: sku,
      previous_material: prevSelection.band_type_group,
      previous_color: prevSelection.color_group,
      full_sku: buildWywCompositeSku(newSelection as WhoopYourWaySelection),
      // *_group instead of *_text because color text is i18nized and this is for analytics
      material: newSelection.band.band_type_group,
      band: newSelection.band.color_group,
      clasp: newSelection.clasp.color_group,
      hook: newSelection.hook.color_group,
      slider: newSelection.slider.color_group,
    });
  };

  const onBandTypeChange = (bandTypeGroup: string): void => {
    const bandType: WhoopYourWayBandTypeOption | undefined =
      bandTypeOptions.find(({ value }) => value === bandTypeGroup);
    updateSelection(
      'band',
      pickRandomBandColorByType(configurations, bandTypeGroup).sku,
    );
    if (bandType) {
      setBandType(bandType);
    }
  };

  const { frontImages, backImages } = getImagesFromSelection(
    configurations.base,
    value,
  );

  const togglePriceBreakdown = (): void => {
    setShowPriceBreakdown(!showPriceBreakdown);
    onAnalyticsEvent?.(
      `WYW ${!showPriceBreakdown ? 'Opened' : 'Closed'} Price Breakdown`,
    );
  };

  const formatDisplayPrice = (price: number): string => {
    return formatPriceWithSiteValues(price, siteValues, { showCents: false });
  };

  const bandPrice = value.band.price;
  const claspPrice = value.clasp.price;
  const hookPrice = value.hook.price;
  const sliderPrice = value.slider.price;
  const totalPrice = bandPrice + hookPrice + sliderPrice + claspPrice;
  const priceMinusFee = bandPrice - customizationFee;

  const discountedPrice = formatDisplayPrice(
    Math.ceil(discountPriceByPercent(totalPrice, discountPercent ?? 0)),
  );

  const price = discountPercent
    ? discountedPrice
    : formatDisplayPrice(totalPrice);
  const originalPrice =
    Boolean(discountPercent) && formatDisplayPrice(totalPrice);

  const priceBreakdown: SubtotalLine[] = [];
  const addPriceBreakdown = (label: string, price: number) => {
    const discountedPrice = formatPriceWithSiteValues(
      discountPriceByPercent(price, discountPercent ?? 0),
      siteValues,
      { showCents: true, roundUp: false },
    );
    priceBreakdown.push({
      label: `${label}`,
      price: showAsFree
        ? t('free')
        : discountPercent
        ? discountedPrice
        : formatDisplayPrice(price),
      originalPrice: discountPercent ? formatDisplayPrice(price) : undefined,
    });
  };
  addPriceBreakdown(t('customization'), customizationFee);
  addPriceBreakdown(t('band'), priceMinusFee);
  claspPrice && addPriceBreakdown(t('clasp'), claspPrice);
  hookPrice && addPriceBreakdown(t('hook'), hookPrice);
  sliderPrice && addPriceBreakdown(t('fastLink'), sliderPrice);

  return (
    <>
      <PreloadedImages images={allImages} />
      <ProductDetailsGrid
        accordions={accordions}
        description={description}
        footer={footer}
        imageMessage={imageMessage}
        infoClassName={infoClassName}
        media={[
          <LayeredImage images={frontImages} key='front' />,
          <LayeredImage images={backImages} key='back' />,
        ]}
        noImageScroll={noImageScroll}
        onAnalyticsEvent={onAnalyticsEvent}
        onImageChange={(index: number) => {
          onAnalyticsEvent?.('WYW Viewed Image', {
            image: index === 0 ? 'Front' : 'Back',
            image_index: index,
          });
        }}
        originalPrice={originalPrice ? originalPrice : undefined}
        previewClassName={previewClassName}
        price={price}
        reviewStars={reviewStars}
        subTitle={subTitle}
        title={configurations.base.title}
      >
        <button
          className='flex flex-row items-center gap-1 pt-3.5'
          onClick={togglePriceBreakdown}
          type='button'
        >
          <span className='text-base font-normal'>{t('priceBreakdown')} </span>
          {showPriceBreakdown ? (
            <CaretUp
              fill='black'
              height={16}
              title='close price breakdown'
              width={16}
            />
          ) : (
            <CaretDown
              fill='black'
              height={16}
              title='open price breakdown'
              width={16}
            />
          )}
        </button>
        {showPriceBreakdown ? (
          <div
            className={clsx(
              'mt-3.5 max-w-[350px] rounded-lg bg-white px-6 py-3 shadow-[0_0_24px_0_rgba(0,0,0,0.1)]',
            )}
          >
            <Subtotal lines={priceBreakdown} />
          </div>
        ) : null}
        <div className='border-gray-40 border-b pr-2'>
          <h3 className={h3Styles}>
            {t('material')}
            <span className={clsx(selectedOptionStyles, 'text-xl')}>
              {bandType?.label}
            </span>
          </h3>
          <RadioButton
            name='fabric-type'
            onChange={onBandTypeChange}
            options={bandTypeOptions}
            size='small'
            useExternalState
            value={bandType?.value}
          />
          <ul className='py-6'>
            {bandType?.description
              ? bandType.description.map((item) => (
                  <li className={clsx(selectedOptionStyles)} key={item}>
                    {item}
                  </li>
                ))
              : null}
          </ul>
        </div>

        {WhoopYourWayComponentNames.map((componentName) => (
          <React.Fragment key={componentName}>
            <h3 className={h3Styles} id={componentName}>
              {configurations[componentName].title}
              <span
                className={clsx(selectedOptionStyles, 'text-xl font-normal')}
              >
                {value[componentName].price && componentName !== 'band'
                  ? `${value[componentName].color_text} +${formatDisplayPrice(
                      value[componentName].price,
                    )}`
                  : value[componentName].color_text}
              </span>
            </h3>
            <SwatchOptionSelect
              aria-labelledby={componentName}
              className='border-gray-40 border-b pb-6'
              name={configurations[componentName].title}
              onChange={(sku: string) => updateSelection(componentName, sku)}
              options={configurations[componentName].options
                .filter(
                  ({ band_type_group: group }) =>
                    group === bandType?.value || componentName !== 'band',
                  bandType,
                )
                .map(
                  ({
                    sku,
                    swatch,
                    color_text: colorText,
                    price,
                    quantity,
                  }) => ({
                    value: sku,
                    label:
                      price && componentName !== 'band'
                        ? `${colorText} +${formatDisplayPrice(price)}`
                        : colorText,
                    background: `url(${urlFromMedia(swatch)})`,
                    disabled: quantity <= 0,
                  }),
                )}
              showAllOptions
              value={value[componentName].sku}
            />
          </React.Fragment>
        ))}
        {children}
      </ProductDetailsGrid>
    </>
  );
}
