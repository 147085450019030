import type { IconProps } from '../../../components/Icon/Icon.util';

export const Check = ({
  title,
  width = 32,
  height = 32,
  fill = 'none',
  role = 'presentation',
  strokeWidth = 1,
}: IconProps): React.ReactNode => {
  const viewBoxSize = 32;

  return (
    <svg
      fill={fill}
      focusable='false'
      height={height}
      role={role}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
    >
      {title ? <title>{title}</title> : null}

      <path
        d='M11.1469 25.7023C11.5411 26.0992 12.1802 26.0992 12.5744 25.7023L29.7044 8.45376C30.0985 8.05684 30.0985 7.4133 29.7044 7.01638L28.9906 6.29769C28.5964 5.90077 27.9573 5.90077 27.5631 6.29769L12.0034 21.9651C11.9245 22.0445 11.7967 22.0445 11.7179 21.9651L5.43689 15.6407C5.0427 15.2437 4.40359 15.2437 4.00939 15.6407L3.29564 16.3593C2.90145 16.7563 2.90145 17.3998 3.29564 17.7967L11.1469 25.7023Z'
        stroke={fill}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default Check;
