import { useEffect, useState } from 'react';

export const useOkendoReviews = (
  widgetContainer: React.MutableRefObject<any>,
  productId?: string,
): { reviewCount: number } => {
  const [reviewCount, setReviewCount] = useState<number>(0);
  const initialiseWidget = () => {
    (
      window as Window & typeof globalThis & { okeWidgetApi: any }
    ).okeWidgetApi.initWidget(widgetContainer.current);
  };

  // Okendo fires a custom event with a detail: { widget } property. When it is equal to "star-rating", initialize the event listeners
  // This grabs the text content within the rendered widget, letting us know how many reviews there are
  const initEventTracking = (e: Event) => {
    // @ts-expect-error
    if (e.detail.widget === 'star-rating') {
      // the first element child is the okendo container
      // the last child element is the script with the metadata (which contains the review count)
      const dataScriptElement = document.getElementById(`shopify-${productId}`)
        ?.firstElementChild?.lastElementChild?.textContent;
      if (dataScriptElement) {
        const data = JSON.parse(dataScriptElement);
        setReviewCount(data?.reviewCount);
      }
    }
  };

  useEffect(() => {
    if (
      productId &&
      (window as Window & typeof globalThis & { okeWidgetApi: any })
        .okeWidgetApi?.initWidget
    ) {
      initialiseWidget();
    } else {
      document.addEventListener('oke-script-loaded', initialiseWidget);
    }

    document.addEventListener('oke-rendered', initEventTracking);

    return () => {
      document.removeEventListener('oke-script-loaded', initialiseWidget);
      document.removeEventListener('oke-rendered', initEventTracking);
    };
  }, [productId]);

  return { reviewCount };
};
