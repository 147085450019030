export async function shareReferralMessage({
  referralMessage,
  title,
}: {
  referralMessage: string;
  title: string;
}): Promise<boolean> {
  let linkCopiedToClipboard = false;
  // navigator.share doesn't exist on certain browsers
  if (navigator.share) {
    await navigator.share({
      title,
      text: referralMessage,
    });
  } else {
    await navigator.clipboard
      .writeText(referralMessage)
      .then(() => (linkCopiedToClipboard = true));
  }

  return linkCopiedToClipboard;
}
