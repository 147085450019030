import { useEffect, useState } from 'react';
import { cltw } from '../../utils';
import { Button } from '../ButtonV2/Button';

interface RadioButtonOption {
  disabled?: boolean;
  label: string;
  value: string | undefined;
}

export interface RadioButtonProps {
  name: string;
  options: RadioButtonOption[];
  onChange?: (...value: any[]) => void;
  allowNone?: boolean;
  value?: string | number;
  useExternalState?: boolean;
  size?: 'small' | 'medium' | 'large';
}

export default function RadioButton({
  name,
  onChange,
  options,
  allowNone = false,
  value,
  useExternalState = false,
  size = 'medium',
  ...props
}: RadioButtonProps): JSX.Element | null {
  const [selectedValue, setSelectedValue] = useState(value);

  // set value when parent updates value
  useEffect(() => {
    if (value !== undefined) {
      setSelectedValue(value);
    } else if (!allowNone && options.length > 0) {
      setSelectedValue(options[0].value);
    }
  }, [value]);

  if (options.length <= 0) return null;

  const changeValue = (value: any) => {
    if (value === selectedValue) return;
    onChange && onChange(value);
    setSelectedValue(value);
  };

  const actualValue = useExternalState ? value : selectedValue;

  return (
    <div
      aria-labelledby={name}
      className='flex justify-evenly gap-2 whitespace-nowrap'
      role='radiogroup'
      {...props}
    >
      {options.map(({ disabled, label, value }) => {
        const selected = value === actualValue;
        return (
          <Button
            className={cltw(selected ? 'selected' : '', 'rounded-none')}
            disabled={disabled}
            key={`${name}-${value}`}
            onClick={() => changeValue(value)}
            size={size}
            variant={selected ? 'secondary' : 'primary'}
          >
            {label}
          </Button>
        );
      })}
    </div>
  );
}
