import Filter from 'bad-words';
import type { PaymentRequestShippingAddress } from '@stripe/stripe-js';

// Downstream systems do not support non-latin characters (XB). Thus regex testing each address field to ensure it only
// contains latin characters. Refer to GRO-2945 comments prior to making changes to ensure system stability.
const basicLatin = '\u{0000}-\u{007F}';
const latin1Supplement = '\u{0080}-\u{00FF}';

export const latinCharacterRegex = new RegExp(
  `^[${basicLatin}${latin1Supplement}]+$`,
  'u',
);

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

export const validateEmail = (email: string): boolean => {
  return emailRegex.test(String(email).toLowerCase());
};

const ProfanityFilter = new Filter({
  exclude: ['cox', 'damn', 'dick', 'fanny', 'god', 'hell', 'wang', 'willy'],
});

/**
 * Determines if the provided text contains profanity
 * @param {string} text
 * @returns true if text is profane, false if not
 */
export const hasProfanity = (text): boolean => {
  return ProfanityFilter.isProfane(text);
};

/**
 * Determines if the provided text contains invalid characters
 * @param {string} text
 * @returns true if text is valid, false if not
 */
export const validateString = (text: string): boolean => {
  return latinCharacterRegex.test(text);
};

/**
 * Express Checkout character validation
 * Determines if the provided address contains invalid characters
 *  * If Australian address, checks that the zip, city, and state match
 * Form validation for latin characters can be found in validationSchemas.tsx
 * @param {PaymentRequestShippingAddress} address
 * @param {PaymentRequestShippingAddress} payerPhone
 * @param {PaymentRequestShippingAddress} payerName
 * @returns true if text is valid, false if not
 */
export const validateAddress = ({
  address,
  payerPhone,
  payerName,
}: {
  address: PaymentRequestShippingAddress;
  payerPhone?: string;
  payerName?: string;
}): boolean => {
  if (address.region === 'AU' && !validateAustraliaCityMatch(address)) {
    return false;
  }
  return !Object.values({ ...address, payerPhone, payerName }).some(
    (value?: string | string[]) =>
      Boolean(value) &&
      (typeof value === 'string'
        ? !validateString(value)
        : value?.some((subvalue: string) => !validateString(subvalue))),
  );
};

export const validateAustraliaCityMatch = async (
  address: PaymentRequestShippingAddress,
): Promise<boolean> => {
  if (address.country === 'AU') {
    const australiaMap = (
      await import('@whoop/i18n/library/australian_postcodes.json')
    ).default;
    const state = address.region?.toLowerCase();
    const postalCode = address.postalCode;
    const city = address.city?.toLowerCase();

    return Boolean(
      state &&
        postalCode &&
        australiaMap.some(
          (entry) =>
            entry.state.toLowerCase() === state &&
            entry.postCode.toLowerCase() === postalCode &&
            entry.city.toLowerCase() === city,
        ),
    );
  }
  return true;
};
